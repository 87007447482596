<script>
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';
import qs from 'qs';

export default {
  props: {
    id: { type: String, default: "ModalAccessionCenter" },
    essayId: { type: [Number, String] },
    centerId: { type: Number },
    accesion_center: {
      type: Object,
      default: () => {
        return {
          id: null,
          document_name: null,
          accession_center_professorates: [],
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      dataUser: [],
      selectOptionsTrackingStatus: [],
      selectOptionsActions: [],
      collapseOpen: [],
      loading: false,
      selectOptionsCenter: [],
      dataEssay: {}
    };
  },
  computed: {
    modalTitle() {
        return this.accesion_center?.id ? "Editar adhesión" : "Añadir adhesión";
    },
    submitButtonText() {
      return this.accesion_center?.id ? 'Guardar' : 'Añadir';
    },
  },
  created() {
    this.loadSelectOptionsCenter();
  },
  methods: {
    loadDataEssay(){
      const apiEndpoint = `/api/v1/essays/${this.essayId}`;
      api.get({url:apiEndpoint})
        .then(response => {
          const data = response?.data?.data;
          this.dataEssay = data;
          console.log(this.dataEssay.project.project_type_id);
                   
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    loadSelectOptionsCenter(){
      const apiEndpoint = `/api/v1/centers`;
      api.get({url:apiEndpoint})
      .then(response => {
          this.selectOptionsCenter = response?.data?.data;
      })
      .catch(error => {
          console.error("Error al cargar la lista:", error);
      });
    },
    showElement(roleId = [], show = true, showEdit = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showEdit) {
        return true;
      }
      return false;
    },
    onFileChange(event){
      this.file = event.target.files[0];
      this.accesion_center.document_name = this.file?.name;
      this.uploadFile(this.file, 'accession')
      .then((response) => {
        const file = response?.data?.data;
        this.accesion_center.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    downloadFileAnexoIV(){
      const params = {
        'accesion_center': this.accesion_center,
        'essay_id': this.essayId,
        'accession': true,
        'center_id': this.accesion_center.center_id
      };
      const queryString = qs.stringify(params);
      const baseUrl = process.env.VUE_APP_API_HOST;
      const url = `${baseUrl}/anexo4?${queryString}`;      
      window.open(url, '_blank');
    },
    downloadFiles(fileName, folderID){
        const params = {
            'fileName': fileName,
            'folderID': folderID,
        };

        const queryString = qs.stringify(params);
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/download-drive?${queryString}`;
        window.open(url, '_blank');
    },
    createAccessionCenter() {
      this.loading = true;
      const apiEndpoint = `/api/v1/accession-centers`;
      this.accesion_center.essay_id = this.essayId;
      api.post({url:apiEndpoint, data:this.accesion_center})
      .then(response => {
          this.loading = false;
          const newAccession = response?.data?.data;
          this.$emit('success', newAccession);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al crear adhesion de empresa:", error);
      });
    },

    updateAccessionCenter() {
      this.loading = true;
      const apiEndpoint = `/api/v1/accession-centers`;
      api.put({url:apiEndpoint, data:this.accesion_center, id:this.accesion_center.id})
      .then(response => {
          this.loading = false;
          const updatedAccession = response?.data?.data;
          this.$emit('success', updatedAccession);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al editar adhesion de empresa:", error);
      });
    },
    changeStatusAccession(status){
      this.accesion_center.status = status;
      this.submitForm();
    },
    submitForm() {
      if(this.accesion_center?.id){
          return this.updateAccessionCenter();
      }
      return this.createAccessionCenter();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    addRowProfessorate(){
      this.accesion_center.accession_center_professorates.push({
        id: '',
        name: '',
        department: '',
        dni: '',
        email: '',
        accesion_center_id: this.accesion_center.id,
      });
    },
    deleteRowProfessorate(key){
      this.accesion_center?.accession_center_professorates.splice(key, 1);
    },
    loadData(){
      console.log(this.essayId, typeof this.essayId);
      console.log(this.accesion_center);
      // this.loadSelectOptionsCenter();
    },
  },
  mounted() {
    this.loadDataEssay();    
  },
};
</script>
<template>
  <div>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h6>Justificación</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.reason }}
                  </p>
                  <div v-else class="form-group">
                    <textarea v-model="accesion_center.reason" class="form-control" rows="3" placeholder="Introduce justificación"></textarea>
                  </div>
                </div>
                <div v-if="dataEssay.project.project_type_id" class="col-lg-12">
                  <div class="form-group">
                    <label for="selectedCenter">Centro</label>
                    <select class="form-control" id="selectedCenter" v-model="accesion_center.center_id">
                      <option :value="null">Selecciona centro</option>
                      <option v-for="option in selectOptionsCenter" :value="option.id" :key="option.id">{{ option.name }}</option>
                    </select>
                  </div>
                </div>
                <div v-else>
                  <div class="col-lg-12">
                    <h6>Código del centro</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.center_code }}
                    </p>
                    <div v-else class="form-group">
                      <input v-model="accesion_center.center_code" type="text" class="form-control" id="name" placeholder="Código" required>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h6>Nombre del centro</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.name }}
                    </p>
                    <div v-else class="form-group">
                      <input v-model="accesion_center.name" type="text" class="form-control" id="name" placeholder="Nombre del centro" required>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h6>Nombre del director/a</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.director }}
                    </p>
                    <div v-else class="form-group">
                      <input v-model="accesion_center.director" type="text" class="form-control" id="name" placeholder="Director/a" required>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h6>Dirección</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.address }}
                    </p>
                    <div v-else class="form-group">
                        <textarea v-model="accesion_center.address" class="form-control" rows="3" placeholder="Dirección"></textarea>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <h6>Ciudad</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.city }}
                    </p>
                    <div v-else class="form-group">
                      <input v-model="accesion_center.city" type="text" class="form-control" id="city" placeholder="Ciudad" required>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <h6>Provincia</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.province }}
                    </p>
                    <div v-else class="form-group">
                      <input v-model="accesion_center.province" type="text" class="form-control" id="province" placeholder="Provincia" required>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <h6>Código postal</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.cp }}
                    </p>
                    <div v-else class="form-group">
                      <input v-model="accesion_center.cp" type="text" class="form-control" id="cp" placeholder="Código postal" required>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <h6>Teléfono</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.phone }}
                    </p>
                    <div v-else class="form-group">
                      <input v-model="accesion_center.phone" type="text" class="form-control" id="phone" placeholder="Teléfono" required>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <h6>Email</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.email }}
                    </p>
                    <div v-else class="form-group">
                      <input v-model="accesion_center.email" type="text" class="form-control" id="email" placeholder="Email" required>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-lg-12">
                  <h6>Código del centro</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.center_code }}
                  </p>
                  <div v-else class="form-group">
                    <input v-model="accesion_center.center_code" type="text" class="form-control" id="name" placeholder="Código" required>
                  </div>
                </div>
                <div class="col-lg-12">
                  <h6>Nombre del centro</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.name }}
                  </p>
                  <div v-else class="form-group">
                    <input v-model="accesion_center.name" type="text" class="form-control" id="name" placeholder="Nombre del centro" required>
                  </div>
                </div>
                <div class="col-lg-12">
                  <h6>Nombre del director/a</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.director }}
                  </p>
                  <div v-else class="form-group">
                    <input v-model="accesion_center.director" type="text" class="form-control" id="name" placeholder="Director/a" required>
                  </div>
                </div>
                <div class="col-lg-12">
                  <h6>Dirección</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.address }}
                  </p>
                  <div v-else class="form-group">
                      <textarea v-model="accesion_center.address" class="form-control" rows="3" placeholder="Dirección"></textarea>
                  </div>
                </div>
                <div class="col-lg-4">
                  <h6>Ciudad</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.city }}
                  </p>
                  <div v-else class="form-group">
                    <input v-model="accesion_center.city" type="text" class="form-control" id="city" placeholder="Ciudad" required>
                  </div>
                </div>
                <div class="col-lg-4">
                  <h6>Provincia</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.province }}
                  </p>
                  <div v-else class="form-group">
                    <input v-model="accesion_center.province" type="text" class="form-control" id="province" placeholder="Provincia" required>
                  </div>
                </div>
                <div class="col-lg-4">
                  <h6>Código postal</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.cp }}
                  </p>
                  <div v-else class="form-group">
                    <input v-model="accesion_center.cp" type="text" class="form-control" id="cp" placeholder="Código postal" required>
                  </div>
                </div>
                <div class="col-lg-6">
                  <h6>Teléfono</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.phone }}
                  </p>
                  <div v-else class="form-group">
                    <input v-model="accesion_center.phone" type="text" class="form-control" id="phone" placeholder="Teléfono" required>
                  </div>
                </div>
                <div class="col-lg-6">
                  <h6>Email</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.email }}
                  </p>
                  <div v-else class="form-group">
                    <input v-model="accesion_center.email" type="text" class="form-control" id="email" placeholder="Email" required>
                  </div>
                </div> -->
                <div class="col-lg-12 mt-3">
                  <h6>Profesorado participante</h6>
                    <table class="table table-bordered table-sticky-header">
                      <thead>
                        <tr>
                          <th scope="col">Nombre</th>
                          <th scope="col">Departamento</th>
                          <th scope="col">DNI</th>
                          <th scope="col">Email</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(professorate, i) in accesion_center?.accession_center_professorates" :key="i" class="">
                          <td>
                            <div class="form-group">
                              <input type="text" v-model="professorate.name" class="form-control" placeholder="Nombre" />                                          
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="text" v-model="professorate.department" class="form-control" placeholder="Departamento" />                                     
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="text" v-model="professorate.dni" class="form-control" placeholder="DNI" />                                          
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="text" v-model="professorate.email" class="form-control" placeholder="Email" />                                          
                            </div>
                          </td>
                          <td class="text-center">
                              <a
                                  href="javascript:void(0);"
                                  class="text-danger"
                                  v-b-tooltip.hover
                                  title="Eliminar"
                                  @click="deleteRowProfessorate(i)"
                              >
                                  <i class="mdi mdi-trash-can font-size-18"></i>
                              </a>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="showElement([3])">
                        <tr>
                          <td colspan="4" class="text-right">
                              <a href="javascript:void(0);" class="text-success" @click="addRowProfessorate()">
                                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar profesorado
                              </a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                </div>
              </div>
              <div v-if="showElement([3])" class="row mt-4">
                <div class="col-lg-12">
                  <b-alert show variant="warning">
                    <strong>Observaciones</strong><br>
                    <ul>
                      <li>Antes de adjuntar el <strong>Anexo IV</strong>, primero debes generarlo <a href="javascript:void(0);" @click.prevent="downloadFileAnexoIV()">aquí</a></li>
                      <li>Si has completado los dos campos anteriores, también aparecerán en el <strong>Anexo IV</strong></li>
                    </ul>
                  </b-alert>
                </div>
                <div class="col-lg-6 mt-3">
                  <h6>Adjuntar Anexo VI</h6>
                  <div class="form-group">
                    <div class="custom-file">
                      <input id="inputFile" type="file" name="file" class="custom-file-input" @change="onFileChange" />
                      <label class="custom-file-label" for="inputFile">{{ accesion_center.document_name || 'Seleccione un archivo' }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row mt-3">
                <div class="col-lg-12 mt-3">
                  <h6>Anexo VI</h6>
                  <b-button variant="info" @click.prevent="downloadFiles(accesion_center?.document_name, accesion_center?.essay?.folder_google_id)"><i class="fas fa-file-download mr-2"></i> Descargar</b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <b-button  v-if="showElement([3])" variant="success" @click="submitForm()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>{{ submitButtonText }}</b-button>
        <b-button  v-if="showElement([1])" variant="success" @click="changeStatusAccession('aceptada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Aceptar</b-button>
        <b-button  v-if="showElement([1])" variant="danger" @click="changeStatusAccession('rechazada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Rechazar</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>