<script>
import api from "@/helpers/api-rest/api.js";
import format from 'date-fns/format';

export default {
  props: {
    id: { type: String, default: "ModalActions" },
    action: {
      type: Object,
      default: () => {
        return {
          id: null,
          education_activities: [],
          economic_forecats: [],
          essay_id: null
        };
      },
    },
  },
  data() {
    return {
        loading: false,
    };
  },
  computed: {
    modalTitle() {
        return this.action?.id ? this.action?.name : "Añadir acción";
    },
    submitButtonText() {
      return this.action?.id ? '' : 'Guardar';
    },
  },
  methods: {
    loadData(){
        this.action.init_date = format(new Date(this.action?.init_date), 'dd/MM/yyyy');
        this.action.end_date = format(new Date(this.action?.end_date), 'dd/MM/yyyy');
    },
    updateDateAction(event, field) {
        const value = event.target.value;
        field == 'init_date' ?  this.action.init_date = value : this.action.end_date = value;
    },
    createAction() {
      this.loading = true;
      console.log(this.action);
      const apiEndpoint = `/api/v1/essay-actions`;
      api.post({url:apiEndpoint, data:this.action})
      .then(response => {
          this.loading = false;
          const newAction = response?.data?.data;
          this.$emit('success', newAction);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al crear la acción:", error);
      });
    },
    addRowActivity(action){
        action.education_activities.push({
            id: null,
            name: '',
            methodology: '',
        });
    },
    deleteRowActionActivity(key){
        this.action?.education_activities.splice(key, 1);
    },
    addRowForecast(action){
        action.economic_forecats.push({
            id: null,
            material: '',
            cost: '',
        });
    },
    deleteRowActionForecasts(key){
        this.action?.economic_forecats.splice(key, 1);
    },
    toggle(element) {
      element.showDetail = !element.showDetail;
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
  },
};
</script>
<template>
  
  <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
    <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <h6>Nombre</h6>
                            <p v-if="action?.id" class="text-muted">
                                {{ action?.name }}
                            </p>
                            <div v-else class="form-group">
                                <input type="text" v-model="action.name" class="form-control" id="name" placeholder="Nombre" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <h6>Responsable</h6>
                            <p v-if="action?.id" class="text-muted">
                                {{ action?.responsables }}
                            </p>
                            <div v-else class="form-group">
                                <input type="text" v-model="action.responsables" id="actionResponsable" class="form-control" placeholder="Responsable" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <h6>Ejecución inicial</h6>
                            <p v-if="action?.id" class="text-muted">
                                {{ action?.init_date }}
                            </p>
                            <div v-else class="form-group">
                                <input type="date" @input="updateDateAction($event, 'init_date')" id="initDate" class="form-control" placeholder="Ejecución inicial" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <h6>Ejecución final</h6>
                            <p v-if="action?.id" class="text-muted">
                                {{ action?.end_date }}
                            </p>
                            <div v-else class="form-group">
                                <input type="date" @input="updateDateAction($event, 'end_date')" id="endDate" class="form-control" placeholder="Ejecución final" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12 mt-4">
                        <h6>Descripción</h6>
                        <p v-if="action?.id" class="text-muted">
                            {{ action?.descriptions }}
                        </p>
                        <div v-else class="form-group">
                            <textarea v-model="action.descriptions" class="form-control" id="descriptionAction" rows="3" placeholder="Descripción"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12 mt-3">
                        <h6>Plan de contingencia</h6>
                        <p v-if="action?.id" class="text-muted">
                            {{ action?.contingency_plan }}
                        </p>
                        <div v-else class="form-group">
                            <textarea type="text" v-model="action.contingency_plan" id="actionPlan" class="form-control" rows="3" placeholder="Plan de contingencia"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12 mt-3">
                        <h6>Riesgos</h6>
                        <p v-if="action?.id" class="text-muted">
                            {{ action?.risks }}
                        </p>
                        <div v-else class="form-group">
                            <textarea type="text" v-model="action.risks" id="actionRisks" class="form-control" rows="3" placeholder="Riesgos"></textarea>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Actividades educativas</h4>
                    <table class="table table-centered datatable dt-responsive nowrap mt-4" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Metodología</th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr v-for="(activity) in action.education_activities" :key="activity.id" class="">
                                <td>
                                    <p v-if="action?.id" class="text-muted">
                                        {{ activity?.name }}
                                    </p>
                                    <div v-else class="form-group">
                                        <input type="text" v-model="activity.name" class="form-control" placeholder="Nombre" />                                          
                                    </div>
                                </td>
                                <td>
                                    <p v-if="action?.id" class="text-muted">
                                        {{ activity?.methodology }}
                                    </p>
                                    <div v-else class="form-group">
                                        <input type="text" v-model="activity.methodology" class="form-control" placeholder="Metodología" />                                     
                                    </div>
                                </td>
                                <td v-if="!action?.id" class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowActionActivity(activity.id)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="!action.education_activities || action.education_activities.length == 0">
                                <td colspan="4" class="text-center">
                                    No hay registros
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-if="!action?.id">
                            <tr>
                                <td colspan="8" class="text-right">
                                <a href="javascript:void(0);" class="text-success" @click="addRowActivity(action)">
                                    <i class="mdi mdi-plus-circle font-size-18"></i> Agregar actividad
                                </a>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Previsión de coste económicos</h4>
                    <table class="table table-centered datatable dt-responsive nowrap mt-4" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead>
                            <tr>
                                <th scope="col">Material</th>
                                <th scope="col">Coste (€)</th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr v-for="(forecast) in action.economic_forecats" :key="forecast.id" class="">
                                <td>
                                    <p v-if="action?.id" class="text-muted">
                                        {{ forecast?.material }}
                                    </p>
                                    <div v-else class="form-group">
                                        <input type="text" v-model="forecast.material" class="form-control" placeholder="Material" />                                          
                                    </div>
                                </td>
                                <td>
                                    <p v-if="action?.id" class="text-muted">
                                        {{ forecast?.cost }}
                                    </p>
                                    <div v-else class="form-group">
                                        <input type="text" v-model="forecast.cost" class="form-control" placeholder="Coste" />                                     
                                    </div>
                                </td>
                                <td  v-if="!action?.id" class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowActionForecasts(forecast.id)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="!action.economic_forecats || action.economic_forecats.length == 0">
                                <td colspan="4" class="text-center">
                                    No hay registros
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-if="!action?.id">
                            <tr>
                                <td colspan="8" class="text-right">
                                <a href="javascript:void(0);" class="text-success" @click="addRowForecast(action)">
                                    <i class="mdi mdi-plus-circle font-size-18"></i> Agregar previsión
                                </a>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </form>
    </template>
    <template #modal-footer="{  }">
        <b-button  v-if="!action?.id" variant="success" @click="createAction()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>{{ submitButtonText }}</b-button>
        <!-- <b-button  v-if="showElement([1])" variant="success" @click="changeStatusContinuity('aceptada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading && continuity.status == 'aceptada'"></span>Aceptar</b-button> -->
        <!-- <b-button  v-if="showElement([1])" variant="danger" @click="changeStatusContinuity('rechazada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading && continuity.status == 'rechazada'"></span>Rechazar</b-button> -->
        <b-button @click="cancelForm()">Cancelar</b-button>
    </template>
  </b-modal>
</template>