<script>
import api from "@/helpers/api-rest/api.js";
// import format from 'date-fns/format';

export default {
  props: {
    id: { type: String, default: "ModalTargets" },
    target: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      showResults: false,
      loading: false
    };
  },
  computed: {
    modalTitle() {
        // return this.target?.id ? "Editar seguimiento" : "Añadir seguimiento";
        return this.target?.title;
    },
  },
  methods: {
    loadData(){
      console.log(this.target);
    },
    toggle(element) {
      element.showDetail = !element.showDetail;
    },
    updateTarget() {
      this.loading = true;
      const apiEndpoint = `/api/v1/targets`;
      api.put({url:apiEndpoint, data:this.target, id:this.target.id})
      .then(response => {
          this.loading = false;
          const updatedTarget = response?.data?.data;
          this.$emit('success', updatedTarget);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al editar el objetivo:", error);
      });
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
  },
};
</script>
<template>
  
  <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
    <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <h6>Título</h6>
                            <p class="text-muted">
                                {{ target?.title }}
                            </p>
                        </div>
                        <div class="col-lg-8">
                            <h6>Descripción</h6>
                            <p class="text-muted">
                                {{ target?.description }}
                            </p>
                        </div>
                        <div class="col-lg-12 mt-3">
                            <div>
                                <h6>Indicador de realización de objetivos</h6>
                                <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Descripción</th>
                                            <th>Valores de medida</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="t_achievement in target.target_achievements">
                                            <tr :key="t_achievement.id">
                                            <td>{{ t_achievement.name }}</td>
                                            <td>{{ t_achievement.description }}</td>
                                            <td>{{ t_achievement.measured_values }}</td>
                                            </tr>
                                        </template>
                                        <tr v-if="!target.target_achievements || target.target_achievements.length == 0">
                                            <td colspan="4" class="text-center">
                                            No hay registros
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Resultados</h4>
                    <div class="row">
                        <div class="col-lg-12">
                            <b-card no-body class="mb-1 shadow-lg mt-4" v-for="(result, i) in target.target_results" :key="i">
                                <b-card-header header-tag="header" class="p-3 bg-white" role="tab">
                                    <h6 class="m-0 d-flex justify-content-between align-items-center">
                                        <div class="form-inline">
                                            <span>{{ result.title }}</span>
                                        </div>
                                        <div>
                                            <i v-b-toggle.collapse-results @click="showResults = !showResults" class="mr-4" :class="{'fas fa-chevron-down': !showResults, 'fas fa-chevron-up': showResults}"></i>
                                        </div>
                                    </h6>
                                </b-card-header>
                                <b-collapse id="collapse-results" accordion="variation-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <h6>Título</h6>
                                                    <p class="text-muted">
                                                        {{ result?.title }}
                                                    </p>
                                                </div>
                                                <div class="col-lg-8">
                                                    <h6>Descripción</h6>
                                                    <p class="text-muted">
                                                        {{ result?.description }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-lg-12">
                                                    <h6>Indicador de realización de resultados</h6>
                                                    <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                                        <thead>
                                                            <tr>
                                                                <th>Nombre</th>
                                                                <th>Descripción</th>
                                                                <th>Valores de medida</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="r_achievement in result.result_achievements">
                                                                <tr :key="r_achievement.id">
                                                                <td>{{ r_achievement.name }}</td>
                                                                <td>{{ r_achievement.description }}</td>
                                                                <td>{{ r_achievement.measured_values }}</td>
                                                                </tr>
                                                            </template>
                                                            <tr v-if="!result.result_achievements || result.result_achievements.length == 0">
                                                                <td colspan="4" class="text-center">
                                                                    No hay registros
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-lg-12">
                                                    <h6>Valoración y observaciones del resultado</h6>
                                                    <div class="row mt-3">
                                                        <div class="col-lg-12">
                                                            <h6>Valoración</h6>
                                                            <div class="form-group">
                                                                <textarea v-model="result.assessment" class="form-control" rows="3" placeholder=""></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <h6>Observaciones</h6>
                                                            <div class="form-group">
                                                                <textarea v-model="result.observations" class="form-control" rows="3" placeholder=""></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Valoración del objetivo y observaciones</h4>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6>Valoración</h6>
                            <div class="form-group">
                                <textarea v-model="target.assessment" class="form-control" rows="3" placeholder=""></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6>Observaciones</h6>
                            <div class="form-group">
                                <textarea v-model="target.observations" class="form-control" rows="3" placeholder=""></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </template>
    <template #modal-footer="{  }">
        <b-button variant="success" @click="updateTarget()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Guardar</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
    </template>
  </b-modal>
</template>