<script>
// import api from "@/helpers/api-rest/api.js";
// import format from 'date-fns/format';

export default {
  props: {
    id: { type: String, default: "ModalPartners" },
    partner: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
    parentPartner: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    modalTitle() {
        return this.partner?.name;
    },
  },
  methods: {
    loadData(){
      console.log(this.partner, this.parentPartner);
    },
    downloadFile(filePath){
      const baseUrl = process.env.VUE_APP_API_HOST;
      const url = `${baseUrl}/download?file=${filePath}`;
      window.open(url, '_self');
    },
    toggle(element) {
      element.showDetail = !element.showDetail;
    },
  },
};
</script>
<template>
  
  <b-modal :id="id" hide-footer :title="modalTitle" @shown="loadData" scrollable size="xl">
    <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <!-- Información -->
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Información del centro</h4>
                    <div class="row">
                        <div class="col-lg-4">
                            <h6>Código</h6>
                            <p class="text-muted">
                                {{ partner?.center_code }}
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <h6>Nombre</h6>
                            <p class="text-muted">
                                {{ partner?.name }}
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <h6>Director</h6>
                            <p class="text-muted">
                                {{ partner?.director }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <h6>Dirección</h6>
                            <p class="text-muted">
                                {{ partner?.address }}
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <h6>Ciudad</h6>
                            <p class="text-muted">
                                {{ partner?.city }}
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <h6>Provincia</h6>
                            <p class="text-muted">
                                {{ partner?.province }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <h6>Código postal</h6>
                            <p class="text-muted">
                                {{ partner?.cp }}
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <h6>Teléfono</h6>
                            <p class="text-muted">
                                {{ partner?.phone }}
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <h6>Email</h6>
                            <p class="text-muted">
                                {{ partner?.email }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Botón de descarga -->
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Documento de aprobación del claustro y consejo escolar (Anexo IV)</h4>
                    <b-button variant="info" @click.prevent="downloadFile(parentPartner?.document_path)">
                        <i class="fas fa-file-download mr-2"></i> Descargar
                    </b-button>
                </div>
            </div>
            <!-- Profesorado -->
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Profesorado</h4>
                    <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Departamento</th>
                                <th>DNI</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <template v-for="professorate in parentPartner.essay_professorates">
                                <tr :key="professorate.id">
                                    <td>{{ professorate.name }}</td>
                                    <td>{{ professorate.department }}</td>
                                    <td>{{ professorate.dni }}</td>
                                    <td>{{ professorate.email }}</td>
                                </tr>
                            </template>
                            <tr v-if="!parentPartner.essay_professorates || parentPartner.essay_professorates.length == 0">
                                <td colspan="4" class="text-center">
                                    No hay registros
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </template>
  </b-modal>
</template>